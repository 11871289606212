// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddedToCart_addToCart__MdqqC {\n    display: flex;\n    gap: 6px;\n}\n\n.AddedToCart_addToCart__MdqqC * {\n    pointer-events: none;\n}\n\n.AddedToCart_addToCart__MdqqC h4 {\n    font-family: \"GT Light\";\n    font-size: 0.945em;\n    color: #90ee90;\n    transition: 0.3s all;\n}\n\n.AddedToCart_add__y9aJP {\n    width: 14px;\n    transition: 0.3s all;\n    fill: #90ee90;\n}", "",{"version":3,"sources":["webpack://./src/Components/AddedToCart/AddedToCart.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,oBAAoB;IACpB,aAAa;AACjB","sourcesContent":[".addToCart {\n    display: flex;\n    gap: 6px;\n}\n\n.addToCart * {\n    pointer-events: none;\n}\n\n.addToCart h4 {\n    font-family: \"GT Light\";\n    font-size: 0.945em;\n    color: #90ee90;\n    transition: 0.3s all;\n}\n\n.add {\n    width: 14px;\n    transition: 0.3s all;\n    fill: #90ee90;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addToCart": "AddedToCart_addToCart__MdqqC",
	"add": "AddedToCart_add__y9aJP"
};
export default ___CSS_LOADER_EXPORT___;
