import React, { useState, useEffect } from 'react';
import styles from './EditProducts.module.css'; // Importa el CSS module

const EditProducts = () => {
    const [products, setProducts] = useState([]);
    const [newProduct, setNewProduct] = useState({});
    const [productType, setProductType] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);
    
    const [view, setView] = useState(''); // Estado para seleccionar vista
    const [showAddProductForm, setShowAddProductForm] = useState(false); // Estado para mostrar/ocultar el formulario de agregar producto

    useEffect(() => {
        fetch('http://localhost:5000/api/products')
            .then(response => response.json())
            .then(data => setProducts(data))
            .catch(err => console.error(err));
    }, []);

    const handleInputChange = (e, index, field) => {
        const newProducts = [...products];
        newProducts[index][field] = e.target.value;
        setProducts(newProducts);
    };

    const handleAddProduct = () => {
        if (!newProduct.name || !newProduct.price || !newProduct.desc) {
            alert('Por favor, completa todos los campos requeridos.');
            return;
        }

        const { id, ...productWithoutId } = newProduct;
        const productWithGenre = { ...productWithoutId, genre: productType === 'vape' ? 'Vape' : 'Polos' };

        if (productWithGenre.colors) {
            productWithGenre.colors = productWithGenre.colors.split(',').map(color => color.trim());
        }
        if (productWithGenre.tallas) {
            productWithGenre.tallas = productWithGenre.tallas.split(',').map(talla => talla.trim());
        }
        if (productWithGenre.footage) {
            productWithGenre.footage = productWithGenre.footage.split(',').map(url => url.trim());
        }

        fetch('http://localhost:5000/api/products', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(productWithGenre),
        })
        .then(response => response.json())
        .then(data => {
            setProducts([...products, data]);
            setNewProduct({});
            setProductType('');
            setShowAddProductForm(false); // Oculta el formulario después de agregar el producto
        })
        .catch(err => console.error(err));
    };

    const handleProductTypeChange = (e) => {
        setProductType(e.target.value);
    };

    const handleNewProductChange = (e) => {
        setNewProduct({
            ...newProduct,
            [e.target.name]: e.target.value
        });
    };

    const handleEditProduct = (index) => {
        setEditingIndex(index);
    };

    const handleSaveProduct = () => {
        const productToSave = products[editingIndex];
        
        fetch(`http://localhost:5000/api/products/${productToSave.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(productToSave),
        })
        .then(response => response.json())
        .then(data => {
            const updatedProducts = [...products];
            updatedProducts[editingIndex] = data;
            setProducts(updatedProducts);
            setEditingIndex(null);
        })
        .catch(err => console.error(err));
    };

    const handleCancelEdit = () => {
        setEditingIndex(null);
    };

    const handleDeleteProduct = (index) => {
        const productId = products[index].id;
        
        fetch(`http://localhost:5000/api/products/${productId}`, {
            method: 'DELETE',
        })
        .then(() => {
            const updatedProducts = products.filter((_, i) => i !== index);
            setProducts(updatedProducts);
        })
        .catch(err => console.error(err));
    };

    const handleBackToSelector = () => {
        setView('');
        setShowAddProductForm(false);
        setEditingIndex(null);
    };

    const renderProductFields = () => {
        switch (productType) {
            case 'polo':
                return (
                    <>
                        <input
                            type="text"
                            name="name"
                            placeholder="Nombre"
                            value={newProduct.name || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="surname"
                            placeholder="Surname"
                            value={newProduct.surname || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="price"
                            placeholder="Precio"
                            value={newProduct.price || ''}
                            onChange={handleNewProductChange}
                        />
                        <textarea
                            name="desc"
                            placeholder="Descripción"
                            value={newProduct.desc || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="tela"
                            placeholder="Tela"
                            value={newProduct.tela || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="genre"
                            placeholder="Género"
                            value={newProduct.genre || 'Polos'}
                            disabled
                        />
                        <input
                            type="text"
                            name="colors"
                            placeholder="Colores (separados por coma)"
                            value={newProduct.colors || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="envios"
                            placeholder="Envíos"
                            value={newProduct.envios || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="publishers"
                            placeholder="Instrucciones de lavado"
                            value={newProduct.publishers || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="cover"
                            placeholder="URL de la portada"
                            value={newProduct.cover || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="footage"
                            placeholder="URLs de imágenes (separadas por coma)"
                            value={newProduct.footage || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="tallas"
                            placeholder="Tallas (separadas por coma)"
                            value={newProduct.tallas || ''}
                            onChange={handleNewProductChange}
                        />
                    </>
                );
            case 'vape':
                return (
                    <>
                        <input
                            type="text"
                            name="name"
                            placeholder="Nombre"
                            value={newProduct.name || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="surname"
                            placeholder="Surname"
                            value={newProduct.surname || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="price"
                            placeholder="Precio"
                            value={newProduct.price || ''}
                            onChange={handleNewProductChange}
                        />
                        <textarea
                            name="desc"
                            placeholder="Descripción"
                            value={newProduct.desc || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="contenido"
                            placeholder="Contenido"
                            value={newProduct.contenido || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="nicotina"
                            placeholder="Nicotina"
                            value={newProduct.nicotina || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="bateria"
                            placeholder="Batería"
                            value={newProduct.bateria || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="calada"
                            placeholder="Calada"
                            value={newProduct.calada || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="dimensiones"
                            placeholder="Dimensiones"
                            value={newProduct.dimensiones || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="cover"
                            placeholder="URL de la portada"
                            value={newProduct.cover || ''}
                            onChange={handleNewProductChange}
                        />
                        <input
                            type="text"
                            name="footage"
                            placeholder="URLs de imágenes (separadas por coma)"
                            value={newProduct.footage || ''}
                            onChange={handleNewProductChange}
                        />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className={styles['edit-products']}>
            <h1 className={styles['title']}>Editar Productos</h1>

            {/* Selector de Vista */}
            {view === '' && (
                <div className={styles['view-selector']}>
                    <button onClick={() => setView('add')}className={styles['button']} >Agregar Nuevo Producto</button>
                    <button onClick={() => setView('edit')} className={styles['button']}>Editar Productos Existentes</button>
                    <h2 className={styles['subtitle']}>Agregar Nuevo Producto</h2>

                </div>
                
            )}

            {/* Volver al Inicio */}
            {(view === 'add' || view === 'edit') && (
                <button onClick={handleBackToSelector} className={styles['button']}>
                    Volver al Inicio
                </button>
            )}

            {/* Agregar Nuevo Producto */}
            {view === 'add' && showAddProductForm && (
                <div className={styles['add-product-form']}>
                    <h2 className={styles['subtitle']}>Agregar Nuevo Producto</h2>
                    <select onChange={handleProductTypeChange} value={productType}>
                        <option value="">Selecciona el tipo de producto</option>
                        <option value="polo">Polo</option>
                        <option value="vape">Vape</option>
                    </select>

                    {renderProductFields()}

                    <div className={styles['buttons']}>
                        <button onClick={handleAddProduct} className={styles['button']}>Agregar Producto</button>
                        <button onClick={() => setShowAddProductForm(false)} className={styles['button']}>Cancelar</button>
                    </div>
                </div>
            )}

            {/* Mostrar botón para agregar nuevo producto si no está visible */}
            {view === 'add' && !showAddProductForm && (
                <button onClick={() => setShowAddProductForm(true)} className={styles['button']}>
                    Agregar Nuevo Producto
                </button>
            )}

            {/* Editar Productos Existentes */}
            {view === 'edit' && (
                <div className={styles['edit-existing-products']}>
                    <h2 className={styles['subtitle']}>Editar Productos Existentes</h2>
                    {products.map((prod, index) => (
                        <div key={prod.id} className={styles['product-item']}>
                            {editingIndex === index ? (
                                <>
                                    <label>
                                        Nombre:
                                        <input
                                            type="text"
                                            value={prod.name}
                                            onChange={(e) => handleInputChange(e, index, 'name')}
                                        />
                                    </label>
                                    <br />
                                    <label>
                                        Precio:
                                        <input
                                            type="text"
                                            value={prod.price}
                                            onChange={(e) => handleInputChange(e, index, 'price')}
                                        />
                                    </label>
                                    <br />
                                    <label>
                                        Descripción:
                                        <input
                                            type="text"
                                            value={prod.desc}
                                            onChange={(e) => handleInputChange(e, index, 'desc')}
                                        />
                                    </label>
                                    <br />
                                    {/* Añade más campos según sea necesario */}
                                    <br />
                                    <div className={styles['buttons']}>
                                        <button onClick={handleSaveProduct} className={styles['button']}>Guardar</button>
                                        <button onClick={handleCancelEdit} className={styles['button']}>Cancelar</button>
                                        <button onClick={() => handleDeleteProduct(index)} className={styles['button']}>Borrar</button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p><strong>Nombre:</strong> {prod.name}</p>
                                    <p><strong>Precio:</strong> {prod.price}</p>
                                    <p><strong>Descripción:</strong> {prod.desc}</p>
                                    <button onClick={() => handleEditProduct(index)} className={styles['button']}>Editar</button>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default EditProducts;
