import styles from './Grid.module.css';
import React, { useEffect } from 'react';
import Card from '../Card/Card';
import AnimatedPage from '../../Containers/AnimatedPage/AnimatedPage';
import { v4 as uuidv4 } from 'uuid';

const Grid = props => {
    const {
        shownGames,
        reviewDisplay,
        handleLike,
        handleHoverGame,
        hoverState,
        handleAddToCart,
        grid,
        search,
        searching,
        handleSelectGame,
        cartDisplayed
    } = props;

    useEffect(() => {
        if (grid === false) {
            if (document.getElementsByClassName('gridContainer')) {
                let grid = document.getElementById('gridContainer')
                grid.className = styles.noGrid
            }
        } else if (grid) {
            if (document.getElementById('gridContainer').className === styles.noGrid) {
                let grid = document.getElementById('gridContainer')
                grid.className = styles.gridContainer
            }
        }
    }, [grid])

    return (
    <>
        <div className={styles.reviews} style={{ display: reviewDisplay ? "flex" : "none" }}>
            <h2>¡Aún no hay reseñas!</h2>
            <h3>Podrás añadir algunos pronto.</h3>
        </div>

        <div className={styles.gridContainer} style={{ display: reviewDisplay ? "none" : "grid" }} id="gridContainer">
            {shownGames.length === 0 ? (
                <div className={styles.emptyMessage} >
                <h2 >No hay productos disponibles</h2>
                </div>
            ) : (
                searching === false ? 
                    cartDisplayed ? 
                        shownGames.slice(0, 8).map((game) => (
                            <Card 
                                game={game} 
                                key={game.name} 
                                handleLike={handleLike} 
                                handleHoverGame={handleHoverGame} 
                                handleAddToCart={handleAddToCart} 
                                handleSelectGame={handleSelectGame}
                                hoverState={hoverState}
                            />
                        )) : 
                        shownGames.map((game) => (
                            <Card 
                                game={game} 
                                key={game.name} 
                                handleLike={handleLike} 
                                handleHoverGame={handleHoverGame} 
                                handleAddToCart={handleAddToCart} 
                                handleSelectGame={handleSelectGame}
                                hoverState={hoverState}
                            />
                        )) : 
                    shownGames.filter(game => game.name.toLowerCase().includes(search.toLowerCase())).map((game) => (
                        <Card 
                            game={game} 
                            key={game.name} 
                            handleLike={handleLike} 
                            handleHoverGame={handleHoverGame} 
                            handleAddToCart={handleAddToCart} 
                            handleSelectGame={handleSelectGame}
                            hoverState={hoverState}
                        />
                    ))
            )}
        </div>
    </>
    );
}

export default Grid;
