// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddToCart_addToCart__OsS1E {\n    display: flex;\n    gap: 6px;\n    cursor: pointer;\n}\n\n.AddToCart_addToCart__OsS1E * {\n    pointer-events: none;\n}\n\n.AddToCart_addToCart__OsS1E h4 {\n    font-family: \"GT Light\";\n    font-size: 0.945em;\n    color: #999;\n    transition: 0.3s all;\n}\n\n.AddToCart_add__eGUw4 {\n    width: 14px;\n    transition: 0.3s all;\n}", "",{"version":3,"sources":["webpack://./src/Components/AddToCart/AddToCart.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;IACR,eAAe;AACnB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,WAAW;IACX,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,oBAAoB;AACxB","sourcesContent":[".addToCart {\n    display: flex;\n    gap: 6px;\n    cursor: pointer;\n}\n\n.addToCart * {\n    pointer-events: none;\n}\n\n.addToCart h4 {\n    font-family: \"GT Light\";\n    font-size: 0.945em;\n    color: #999;\n    transition: 0.3s all;\n}\n\n.add {\n    width: 14px;\n    transition: 0.3s all;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addToCart": "AddToCart_addToCart__OsS1E",
	"add": "AddToCart_add__eGUw4"
};
export default ___CSS_LOADER_EXPORT___;
