import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from "framer-motion";
import AnimatedGamePage from '../AnimatedPage/AnimatedGamePage';
import NavBar from '../../Components/NavBar/NavBar';
import { ReactComponent as Arrow } from "../../Resources/image/arrow.svg";
import { ReactComponent as Like } from "../../Resources/image/like.svg";
import Slider from '../../Components/Slider/Slider';
import AddedToCartBig from '../../Components/AddedToCart/AddedToCartBig';
import Cart from '../../Components/Cart/Cart';
import templateGame from '../../utils/templateGame';
import styles from './GamePage.module.css';

const GamePage = (props) => {
  const {
    handleHover,
    hoverState,
    handleHome,
    cartAmount,
    cart,
    search,
    searching,
    handleSearch,
    handleSearchSubmit,
    browsing,
    handleBrowse,
    selectedGame,
    setSelectedGame,
    allGames,
    extended,
    setExtended,
    handleAddToCart,
    handleLike,
    textExtended,
    setTextExtended,
    handleOpenCart,
    handleCloseCart,
    cartDisplayed,
    clearCart,
    handleRemoveFromCart,
    openGamePage
  } = props;

  const [tallasOpen, setTallasOpen] = useState(false);
  const [selectedTalla, setSelectedTalla] = useState(null); // Estado para almacenar la talla seleccionada
  const { gameId } = useParams();
  const location = useLocation();
  const [carouselState, setCarouselState] = useState(0);

  const handleSelectTalla = (talla) => {
    setSelectedTalla(talla);
    setTallasOpen(false); // Cierra el menú de tallas después de seleccionar
  };

  const handleAddToCartWithSize = () => {
    if (selectedGame && selectedGame.genre === "Vape") {
      handleAddToCart(selectedGame, null); // No se requiere talla para vapes
    } else if (selectedTalla) {
      handleAddToCart(selectedGame, selectedTalla);
    } else {
      alert("Please select a size before adding to cart.");
    }
  };

  const incrementCarousel = () => {
    if (carouselState === 3) {
      setCarouselState(0);
    } else {
      setCarouselState(carouselState + 1);
    }
  };

  const decrementCarousel = () => {
    if (carouselState === 0) {
      setCarouselState(3);
    } else {
      setCarouselState(carouselState - 1);
    }
  };

  const extendText = () => {
    setTextExtended(!textExtended);
  };

  const handleExtend = () => {
    setExtended(!extended);
    if (textExtended === false) {
      setTimeout(extendText, 500);
    } else {
      setTextExtended(!textExtended);
    }
  };

  const colorCodes = {
    Blanco: '#FFFFFF',
    Negro: '#000000',
    Azul: '#0000FF',
    Rojo: '#FF0000',
    Verde: '#008000'
    // Añade más colores según sea necesario
  };

  return (
    <>
      <div className={styles.gamepage}>
        {cartDisplayed ? <Cart
          cartDisplayed={cartDisplayed}
          handleOpenCart={handleOpenCart}
          handleCloseCart={handleCloseCart}
          cart={cart}
          cartAmount={cartAmount}
          handleHover={handleHover}
          hoverState={hoverState}
          clearCart={clearCart}
          handleRemoveFromCart={handleRemoveFromCart}
          openGamePage={openGamePage}
          selectedTalla={selectedTalla} // Pasa la talla seleccionada

        /> : null}

        <NavBar
          handleHover={handleHover}
          hoverState={hoverState}
          handleHome={handleHome}
          browsing={browsing}
          cartAmount={cartAmount}
          search={search}
          searching={searching}
          handleSearch={handleSearch}
          handleSearchSubmit={handleSearchSubmit}
          handleOpenCart={handleOpenCart}
          handleCloseCart={handleCloseCart}
        />

        <AnimatedGamePage>
          <div className={styles.gamepageContent}>
            <header>
              <button
                style={{ color: hoverState[19].hovered ? "#92f" : "#cccccc" }}
                className={styles.goBack}
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                onClick={handleBrowse}
                id="19"
                aria-label='Back'
              >
                <Arrow style={{ fill: hoverState[19].hovered ? "#92f" : "#cccccc" }} className={styles.arrow} />
                Store
              </button>

              <h1>{selectedGame ? selectedGame.name : templateGame.name}</h1>
            </header>

            <section className={styles.game}>
              {<Slider
                selectedGame={selectedGame}
                setSelectedGame={setSelectedGame}
                allGames={allGames}
                incrementCarousel={incrementCarousel}
                decrementCarousel={decrementCarousel}
                carouselState={carouselState}
                setCarouselState={setCarouselState}
                hoverState={hoverState}
                handleHover={handleHover}
              />}
              <div className={styles.gameInfo}>
                <div className={styles.about}>
                  <div className={styles.aboutTop}>
                    <h2>About</h2>
                    <p>{selectedGame ? selectedGame.desc : templateGame.desc}</p>
                  </div>
                  <div
                    className={extended ? `${styles.conditionalOpen} ${styles.aboutBottom}` : `${styles.conditionalClose} ${styles.aboutBottomClosed}`}
                    id="about"
                  >
                    <div className={textExtended ? styles.open : styles.closed}>
                      <a href={selectedGame ? selectedGame.link : templateGame.link} target="_blank">
                        {selectedGame ? selectedGame.name : "No"}
                      </a>
                      {selectedGame && selectedGame.genre === "Vape" ? (
                        <>
                          <h4>Contenido: {selectedGame.contenido || templateGame.contenido}</h4>
                          <h4>Nivel de Nicotina: {selectedGame.nicotina || templateGame.nicotina}</h4>
                          <h4>Capacidad de la batería recargable: {selectedGame.bateria || templateGame.bateria}</h4>
                          <h4>Calada: {selectedGame.calada || templateGame.calada}</h4>
                          <h4>Dimensiones: {selectedGame.dimensiones || templateGame.dimensiones}</h4>
                          <h4>Género: {selectedGame.genre || templateGame.genre}</h4>
                        </>
                      ) : (
                        <>
                          <div className={styles.menuContainer}>
                            <div className={styles.tallaSection}>
                              <span className={styles.dropdownTitle}>Talla: {selectedTalla || "Selecciona una talla"}</span>
                              <div
                                className={styles.dropdownTitle}
                                onClick={() => setTallasOpen(!tallasOpen)}
                                aria-label="Toggle Tallas Dropdown"
                              >
                                <span className={styles.dropdownIcon}>{tallasOpen ? '▲' : '▼'}</span>
                              </div>
                            </div>
                            <AnimatePresence>
                              {tallasOpen && (
                                <motion.div
                                  initial={{ opacity: 0, height: 0 }}
                                  animate={{ opacity: 1, height: 'auto' }}
                                  exit={{ opacity: 0, height: 0 }}
                                  transition={{ duration: 0.2 }}
                                  className={styles.dropdownContent}
                                >
                                  <ul className={styles.dropdownList}>
                                    {selectedGame && selectedGame.tallas ? (
                                      selectedGame.tallas.map((talla, index) => (
                                        <li
                                          key={index}
                                          onClick={() => handleSelectTalla(talla)}
                                          className={selectedTalla === talla ? styles.selectedTalla : ''}
                                        >
                                          {talla}
                                        </li>
                                      ))
                                    ) : (
                                      <li>No hay tallas disponibles</li>
                                    )}
                                  </ul>
                                </motion.div>
                              )}
                            </AnimatePresence>
                          </div>

                          {selectedGame && selectedGame.colors ? (
                            <div className={styles.colors}>
                              <h4>Colores disponibles:</h4>
                              <div className={styles.colorCircles}>
                                {selectedGame.colors.map((color, index) => (
                                  <div
                                    key={index}
                                    className={styles.colorCircle}
                                    style={{ backgroundColor: colorCodes[color] || '#CCCCCC' }}
                                    title={color}
                                  ></div>
                                ))}
                              </div>
                            </div>
                          ) : null}
                          <h4 className={styles.lastChild}>Instrucciones de uso: {selectedGame.publishers || templateGame.publishers}</h4>
                        </>
                      )}
                    </div>

                    <button
                      onClick={handleExtend}
                      onMouseEnter={handleHover}
                      onMouseLeave={handleHover}
                      className={hoverState[20].hovered ? styles.buttonHovered : styles.buttonNotHovered}
                      aria-label="Extend"
                    >
                      {extended ? "Hide" : "More"}
                    </button>
                  </div>
                </div>

                <div className={styles.addToCart}>
                  <div className={styles.infos}>
                    <h3>S/{selectedGame ? selectedGame.price : templateGame.price}</h3>
                    <button id={selectedGame ? selectedGame.id : templateGame.id} onClick={handleLike} aria-label="Like">
                      <Like
                        className={selectedGame ? selectedGame.isLiked ? styles.liked : styles.like : styles.like}
                      />
                    </button>
                  </div>
                    <button
                      onMouseEnter={handleHover}
                      onMouseLeave={handleHover}
                      onClick={handleAddToCartWithSize}
                      className={hoverState[21].hovered ? styles.buttonHovered : styles.buttonNotHovered}
                      aria-label="Add to Cart"
                    >
                      Add to cart
                    </button>
                </div>
              </div>
            </section>
          </div>
          <AddedToCartBig
        itemAdded={true}
        addedGame={selectedGame}
        selectedTalla={selectedTalla} // Pasa la talla seleccionada
      />
        </AnimatedGamePage>
      </div>

    </>
  );
};

export default GamePage;
