import styles from './Footer.module.css';
import React from 'react';
import { ReactComponent as Cart } from "../../Resources/image/cart.svg";
import { ReactComponent as AppStore } from "../../Resources/image/appstorebadge.svg";

const Footer = props => {
    return (
        <div className={styles.footer}>
            <div className={styles.footerTop}>
                <Cart className={styles.logo} />
                <h2>MGVAPE SHOP</h2>
            </div>
            <div className={styles.sections}>
                <div className={`${styles.section} ${styles.section1}`}>
                    <h3 className={styles.first}>Nuestra Tienda</h3>
                    <h3>Sobre Nosotros</h3>
                    <h3>Localización</h3>
                </div>
                <div className={`${styles.section} ${styles.section2}`}>
    <h3 className={styles.first}>Colecciones</h3>
    <h3>Vapes desechables</h3>
    <h3>Vapes recargables</h3>
    <h3>Accesorios</h3>
</div>

<div className={`${styles.section} ${styles.section3}`}>
    <h3 className={styles.first}>Recursos</h3>
    <a 
        href={require('../../Resources/pdf/libro.pdf')} 
        target="_blank" 
        rel="noopener noreferrer"
    >
        <h3>Libro de Reclamaciones</h3>
    </a>
    <a 
        href={require('../../Resources/pdf/envios.pdf')} 
        target="_blank" 
        rel="noopener noreferrer"
    >
        <h3>Envíos</h3>
    </a>
    <a 
        href={require('../../Resources/pdf/devoluciones.pdf')} 
        target="_blank" 
        rel="noopener noreferrer"
    >
        <h3>Devoluciones</h3>
    </a>
</div>

                <div className={`${styles.section} ${styles.section4}`}>
                    <h3 className={styles.first}>Asistencia</h3>
                    <a 
                      href="https://wa.me/923234529" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <h3>Soporte</h3>
                    </a>
                    <a 
                      href="https://wa.me/923234529" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <h3>Contáctanos</h3>
                    </a>
                </div>
            </div>

            <div className={styles.footerInfo}>
                <div className={styles.infoLeft}>
                    <p><span>© MG VAPE 2024</span></p>
                </div>
                <div className={styles.infoRight}>
                    <img className={styles.google} src={require("../../Resources/image/googleplaybadge.png")} alt="Google Play Badge" />
                    <AppStore className={styles.apple} />
                </div>
            </div>

            <div className={styles.footerEnd}>
                <div className={styles.endLeft}>
                    <h4>Privacy</h4>
                    <h4>Security</h4>
                    <h4>Cookies</h4>
                    <h4>Legal</h4>
                </div>

                <div className={styles.endRight}>
                    <a href="https://www.instagram.com/mg_vape_pe/" target="_blank" rel="noopener noreferrer">
                        <img className={styles.social} src={require("../../Resources/image/instagram.png")} alt="Instagram Logo" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Footer;
