// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddedToCartBig_addToCart__qlder {\n    display: flex;\n    gap: 6px;\n}\n\n.AddedToCartBig_addToCart__qlder * {\n    pointer-events: none;\n}\n\n.AddedToCartBig_addToCart__qlder h2 {\n    font-family: \"GT Light\";\n    font-size: 22px;\n    color: #90ee90;\n    transition: 0.3s all;\n}\n\n.AddedToCartBig_add__awgvn {\n    width: 18px;\n    transition: 0.3s all;\n    fill: #90ee90;\n}", "",{"version":3,"sources":["webpack://./src/Components/AddedToCart/AddedToCartBig.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,uBAAuB;IACvB,eAAe;IACf,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,oBAAoB;IACpB,aAAa;AACjB","sourcesContent":[".addToCart {\n    display: flex;\n    gap: 6px;\n}\n\n.addToCart * {\n    pointer-events: none;\n}\n\n.addToCart h2 {\n    font-family: \"GT Light\";\n    font-size: 22px;\n    color: #90ee90;\n    transition: 0.3s all;\n}\n\n.add {\n    width: 18px;\n    transition: 0.3s all;\n    fill: #90ee90;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addToCart": "AddedToCartBig_addToCart__qlder",
	"add": "AddedToCartBig_add__awgvn"
};
export default ___CSS_LOADER_EXPORT___;
