import React, { useState } from 'react';
import styles from './Home.module.css';
import NavBar from '../../Components/NavBar/NavBar';
import { ReactComponent as GitHubLogo } from "../../Resources/image/githublogo.svg";
import { ReactComponent as Enter } from "../../Resources/image/enter.svg";
import { ReactComponent as Ig } from "../../Resources/image/ig.svg";
import { ReactComponent as Wsp } from "../../Resources/image/wsp.svg";
import { ReactComponent as Face } from "../../Resources/image/fb.svg";
import { ReactComponent as Game } from "../../Resources/image/game.svg";
import { ReactComponent as NotFound } from "../../Resources/image/notfound.svg";
import { ReactComponent as NotFoundQuery } from "../../Resources/image/notfoundquery.svg";
import { ReactComponent as Git } from "../../Resources/image/git.svg";
import { ReactComponent as Performance } from "../../Resources/image/performance.svg";
import { ReactComponent as Sources } from "../../Resources/image/sources.svg";
import { motion, AnimatePresence, m } from "framer-motion";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Cart from '../../Components/Cart/Cart';
import AnimatedScroll from '../AnimatedPage/AnimatedScroll';
import product from '../../utils/product';
import image1 from '../../Resources/image/1.png'; // Ajusta la ruta a tu imagen
import video from '../../Resources/image/fondo4.mp4'; // Asegúrate de ajustar la ruta de tu video


const Home = props => {
  const {
    shownGames,
    cartAmount,
    cart,
    cartDisplayed,
    handleOpenCart,
    handleCloseCart,
    clearCart,
    handleRemoveFromCart,
    hoverState,
    setHoverState,
    overlap,
    setOverlap,
    openGamePage
  } = props;

  const [browsing, setBrowsing] = useState(false);
  const [landingPage, setLandingPage] = useState(true);

  const navigate = useNavigate();

  const handleHover = (e) => {
    let newHoverState = hoverState[e.target.id];
    newHoverState.hovered = !newHoverState.hovered;

    setHoverState([
        ...hoverState, hoverState[e.target.id] = newHoverState
    ]);
  }

  const handleBrowse = () => {
    setOverlap(true);
    setTimeout(() => {
      setBrowsing(true);
      navigate('/home/browse');
    }, 1500);
  }

  const handleHome = () => {
    setBrowsing(false);
    navigate('/');
  }

  const handleNavGamePage = () => {
    setHoverState([...hoverState, hoverState[21].hovered = false]);
    navigate('/home/product/riseofthetombraider');
  }
  
  const handleNavNotFoundPage = () => {
    navigate('/home/this-page');
  }
  
  const handleNavNotFoundQuery = () => {
    navigate('/home/product/404');
  }
  
  const handlePlayDice = () => {
    let randomIndex = Math.floor(Math.random() * 32);
    let randomSurname = product[randomIndex].surname;
    setOverlap(true);
    setTimeout(() => {
      setBrowsing(true);
      navigate(`/home/product/${randomSurname}`);
    }, 1500);
  }

  const variants = {
    hidden: { opacity: 1, x: -150 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 150 },
  }

  const buttonVariants = {
    hidden: { opacity: 0, y: 900 },
    visible: { opacity: 1, y: 0, transition: {  y: { type: "tween", duration: 1.5, bounce: 0.3 }} },
  }

  return (
    <div className={styles.main}>
  <div className={styles.videoBackground}>
    {/* Fondo verde semitransparente */}
    <div className={styles.greenOverlay}></div>

    <video autoPlay muted loop className={styles.video}>
      <source src={video} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>     {overlap ? 
          <motion.div 
            className={styles.overlap}
            variants={buttonVariants}
            initial="hidden"
            animate="visible"
          >
    
          </motion.div> 
      : null}

      {cartDisplayed ? <Cart 
              cartDisplayed={cartDisplayed} 
              handleOpenCart={handleOpenCart}
              handleCloseCart={handleCloseCart}
              cart={cart}
              cartAmount={cartAmount}
              handleHover={handleHover}
              hoverState={hoverState}
              clearCart={clearCart}
              handleRemoveFromCart={handleRemoveFromCart}
              openGamePage={openGamePage}
      /> : null}
        <div className={styles.home}>

               

                <NavBar 
                  handleHover={handleHover} 
                  hoverState={hoverState}
                  browsing={browsing}
                  handleBrowse={handleBrowse}
                  handleHome={handleHome}
                  landingPage={landingPage}
                  cartAmount={cartAmount}
                  handleOpenCart={handleOpenCart}
                  handleCloseCart={handleCloseCart}
                />
                <div className={styles.container}>
                    <div className={styles.left}>
                        <div className={styles.splash}>
                        <h1 className={styles.largeScreenText}>¿Quienes somos?</h1>
                       <h1 className={styles.smallScreenText}>Dakitv store</h1>

                          <p className={styles.intro}>Somos una empresa emprendedora dedicada a la venta de Vape ( Cigarros Electrónicos ) EN MODELOS DESCARTABLES Y RECARGABLES Apasionados por la moda E innovación, ofrecemos productos únicos que reflejan en cada momento del vapeo. <span className={styles.careers}>Nos esforzamos por brindar una experiencia de compra exclusiva y mantenernos a la vanguardia de las tendencias</span></p>
                        </div>
    
                        <div className={styles.buttons}>
                              <button className={`${styles.cta} ${styles.browseBtn}`} onClick={handleBrowse} aria-label="Browse">
                                <Enter className={styles.ctaSVG} />
                                Shop
                              </button>
                              <a href="https://wa.me/923234529" target="_blank"><button className={styles.cta} aria-label="Abrir Whatsapp">
                                <Wsp className={styles.ctaSVG} />
                              Whatsapp
                              </button></a>
                              <a href="https://www.instagram.com/mg_vape_pe/" target="_blank"><button className={styles.cta} aria-label="Abrir ig">
                                <Ig className={styles.ctaSVG} />
                                Instagram
                              </button></a>
                              <a href="https://www.tiktok.com/@mg_vape_pe" target="_blank"><button className={`${styles.cta} ${styles.lastChild}`} aria-label="Abrir shop">
                                <Face className={`${styles.ctaSVG} ${styles.linkedin}`} />
                                <span>Tik Tok</span>
                              </button></a>
                        </div>
                    </div>
                    
                    <div className={styles.right}>
                    <img src={image1} alt="Description of the image" className={styles.image} />

                    </div>
                    
                </div>
                
        </div>
    </div>
  );
}

export default Home;