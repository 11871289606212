const templateGame = {
    name: " not found",
    surname: "404",
    price: "??.??",
    desc: '',
    link: '',
    release: 'No release date found',
    platforms: 'None',
    genre: 'None',
    developers: 'None',
    publishers: 'None',
    inCart: false,
    selected: false,
    isHovered: false,
    isLiked: false,
    rating: 0,
    id: 33,
    cover: "",
    footage: [
        "",
        "",
        "",
        ""
    ]
}

export default templateGame;