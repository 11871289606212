import styles from './Slider.module.css';
import React, { useEffect, useState } from 'react';
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { ReactComponent as Left } from "../../Resources/image/left.svg";
import { ReactComponent as Right } from "../../Resources/image/right.svg";
import { useLocation } from 'react-router-dom';
import templateGame from '../../utils/templateGame';

// Import all images from the shop folder
function importAll(r) {
  let images = {};
  r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../../Resources/shop', false, /\.(png|jpe?g|svg)$/));

const Slider = props => {
  const {
    selectedGame,
    setSelectedGame,
    allGames,
    incrementCarousel,
    decrementCarousel,
    carouselState,
    setCarouselState,
    hoverState,
    handleHover
  } = props;

  const [footageIndex, setFootageIndex] = useState(0);
  const slideRef = React.createRef();
  const location = useLocation();

  useEffect(() => {
    const selectedGameIndex = allGames.findIndex(product => "/home/product/" + product.surname === location.pathname);
    setSelectedGame(allGames[selectedGameIndex]);
  }, [allGames, location.pathname, setSelectedGame]);

  const properties = {
    duration: 6000,
    autoplay: false,
    transitionDuration: 800,
    arrows: false,
    infinite: true,
    easing: "ease"
  };

  const slideImages = selectedGame ? selectedGame.footage.map(img => images[img.replace('./Resources/shop/', '')]) : [];
  const templateImages = templateGame.footage.map(img => images[img.replace('./Resources/shop/', '')]);

  const back = () => {
    setCarouselState(carouselState > 0 ? carouselState - 1 : slideImages.length - 1);
    slideRef.current.goBack();
  }

  const next = () => {
    setCarouselState(carouselState < slideImages.length - 1 ? carouselState + 1 : 0);
    slideRef.current.goNext();
  }

  const jumpToIndex = (e) => {
    const index = parseInt(e.target.id);
    setCarouselState(index);
    slideRef.current.goTo(index);
  }

  return (
    <div className={styles.slider}>
      <Slide ref={slideRef} {...properties}>
        {slideImages.length > 0
          ? slideImages.map((each, index) => (
            each ? (
              <div key={index} className={styles.slide}>
                <img className={styles.currentImg} src={each} alt={`Slide ${index}`} />
              </div>
            ) : null
          ))
          : templateImages.map((each, index) => (
            each ? (
              <div key={index} className={styles.slide}>
                <img className={styles.currentImg} src={each} alt={`Template Slide ${index}`} />
              </div>
            ) : null
          ))
        }
      </Slide>

      {slideImages.length > 1 && (
        <>
          <button
            className={styles.backwards}
            onClick={back}
            id="22"
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
            aria-label="Previous Picture"
          >
            <Left className={styles.left} style={{ fill: hoverState[22].hovered ? "#fff" : "#ccc" }} />
          </button>

          <button
            className={styles.forward}
            onClick={next}
            id="23"
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
            aria-label="Next Picture"
          >
            <Right className={styles.right} style={{ fill: hoverState[23].hovered ? "#fff" : "#ccc" }} />
          </button>
        </>
      )}

      <div className={styles.selectorContainer}>
        {slideImages.map((_, index) => (
          <button
            key={index}
            id={index.toString()}
            onClick={jumpToIndex}
            className={carouselState === index ? styles.buttonSelected : styles.button}
            aria-label={`Jump to picture ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
}

export default Slider;
