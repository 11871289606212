import React from "react";
import styles from "./Filters.module.css";
import { ReactComponent as Wishlist } from "../../Resources/image/wishlist.svg";
import { ReactComponent as Ratings } from "../../Resources/image/ratings.svg";
import { ReactComponent as Reviews } from "../../Resources/image/reviews.svg";
import { ReactComponent as Polo } from "../../Resources/image/polo.svg";
import { ReactComponent as Vape } from "../../Resources/image/vape.svg";
import { ReactComponent as Strategy } from "../../Resources/image/strategy.svg";
import { ReactComponent as RPG } from "../../Resources/image/RPG.svg";
import { ReactComponent as Medias } from "../../Resources/image/medias.svg";
import { ReactComponent as Adventure } from "../../Resources/image/adventure.svg";
import { ReactComponent as Puzzle } from "../../Resources/image/puzzle.svg";
import { ReactComponent as Racing } from "../../Resources/image/racing.svg";
import { ReactComponent as Sports } from "../../Resources/image/sports.svg";

const Filters = props => {
    const {
        hoverState,
        handleHover,
        handleSelect,
        currentFilter
    } = props;

    return (
        <div className={styles.filters}>
          <h2>Filters</h2>

          <div className={styles.globalFilters}>
               <div 
                 className={styles.filterDiv} 
                 id="8" 
                 onMouseEnter={handleHover} 
                 onMouseLeave={handleHover} 
                 onClick={handleSelect}
               >
                 <button 
                   className={styles.filterBtn} 
                   style={{ backgroundColor: (hoverState[8].hovered || currentFilter == "Wishlist") ? "#fff" : "#2d2d2d" }}
                   aria-label="Open wishlist"
                 >
                     <Wishlist 
                        style={{ fill: (hoverState[8].hovered || currentFilter == "Wishlist") ? "#000000" : "#fff" }} 
                        className={styles.Wishlist}
                     />
                 </button>
                 Lista de deseos
               </div>
     
               <div 
                 className={styles.filterDiv} 
                 id="9" 
                 onMouseEnter={handleHover} 
                 onMouseLeave={handleHover} 
                 onClick={handleSelect}
               >
                 <button 
                   className={`${styles.filterBtn2} ${styles.Ratings}`} 
                   style={{ backgroundColor: (hoverState[9].hovered || currentFilter == "Ratings") ? "#fff" : "#2d2d2d" }}
                   aria-label="Sort after ratings"
                 >
                     <Ratings
                       className={`${styles.filterSVG2} ${styles.Ratings}`} 
                       style={{ fill: (hoverState[9].hovered || currentFilter == "Ratings") ? "#000000" : "#fff" }} 
                     />
                 </button>
                 Calificaciones
               </div>
     
               <div 
                 className={styles.filterDiv}
                 id="10" 
                 onMouseEnter={handleHover} 
                 onMouseLeave={handleHover} 
                 onClick={handleSelect}
               >
                 <button 
                   className={`${styles.filterBtn3} ${styles.Reviews}`} 
                   style={{ backgroundColor: (hoverState[10].hovered || currentFilter == "Reviews") ? "#fff" : "#2d2d2d" }}
                   aria-label="Sort after reviews"
                 >
                     <Reviews
                       className={`${styles.filterSVG3} ${styles.Reviews}`} 
                       viewBox="0 0 48 48" 
                       style={{ fill: (hoverState[10].hovered || currentFilter == "Reviews") ? "#000000" : "#fff" }} 
                     />
                 </button>
                 Reseñas
               </div>
          </div>

          <div className={styles.genreFilters}>
            <h2>Genres</h2>
{/*
            <div 
              className={styles.filterDiv} 
              id="11" 
              onMouseEnter={handleHover} 
              onMouseLeave={handleHover} 
              onClick={handleSelect}
            >
                 <button 
                   className={styles.filterBtn3} 
                   style={{ backgroundColor: (hoverState[11].hovered || currentFilter == "Polos") ? "#fff" : "#2d2d2d" }}
                   aria-label="Show Polos genre"
                 >
                    <Polo 
                      className={styles.filterSVG3}
                      style={{ fill: (hoverState[11].hovered || currentFilter == "Polos") ? "#000000" : "#fff" }} 
                    />
                 </button>
                 Polos
            </div>

            <div 
              className={styles.filterDiv} 
              id="12" 
              onMouseEnter={handleHover} 
              onMouseLeave={handleHover} 
              onClick={handleSelect}
            >
                 <button 
                   className={styles.filterBtn2} 
                   style={{ backgroundColor: (hoverState[12].hovered || currentFilter == "Conjuntos") ? "#fff" : "#2d2d2d" }}
                   aria-label="Show Conjuntos genre"
                 >
                     <Strategy 
                       className={styles.filterSVG2} 
                       style={{ fill: (hoverState[12].hovered || currentFilter == "Conjuntos") ? "#000000" : "#fff" }} 
                     />
                 </button>
                 Conjuntos
            </div>
*/}
            <div 
              className={styles.filterDiv} 
              id="13" 
              onMouseEnter={handleHover} 
              onMouseLeave={handleHover} 
              onClick={handleSelect}
            >
                 <button 
                   className={styles.filterBtn3} 
                   style={{ backgroundColor: (hoverState[13].hovered || currentFilter == "Vapes") ? "#fff" : "#2d2d2d" }}
                   aria-label="Show Polos genre"
                 >
                     <Vape
                       className={styles.filterSVG3} 
                       style={{ fill: (hoverState[13].hovered || currentFilter == "Vapes") ? "#000000" : "#fff" }} 
                      />
                 </button>
                 Vapes
            </div>

            <div 
              className={styles.filterDiv} 
              id="14" 
              onMouseEnter={handleHover} 
              onMouseLeave={handleHover} 
              onClick={handleSelect}
            >
                 <button 
                   className={styles.filterBtn2} 
                   style={{ backgroundColor: (hoverState[14].hovered || currentFilter == "Calcetines") ? "#fff" : "#2d2d2d" }}
                   aria-label="Show Shooter genre"
                 >
                     <Medias 
                       className={styles.filterSVGm2} 
                       style={{ fill: (hoverState[14].hovered || currentFilter == "Calcetines") ? "#000000" : "#fff" }} 
                      />
                 </button>
                 Liquidos
            </div>
      {/*
            <div 
              className={styles.filterDiv} 
              id="15" 
              onMouseEnter={handleHover} 
              onMouseLeave={handleHover} 
              onClick={handleSelect}
            >
                 <button 
                   className={styles.filterBtn2} 
                   style={{ backgroundColor: (hoverState[15].hovered || currentFilter == "Adventure") ? "#fff" : "#2d2d2d" }}
                   aria-label="Show Adventure genre"
                 >
                     <Adventure 
                       className={styles.filterSVG2} 
                       style={{ fill: (hoverState[15].hovered || currentFilter == "Adventure") ? "#000000" : "#fff" }} 
                      />
                 </button>
                 Adventure
            </div>

            <div 
              className={styles.filterDiv} 
              id="16" 
              onMouseEnter={handleHover} 
              onMouseLeave={handleHover} 
              onClick={handleSelect}
            >
                 <button 
                   className={styles.filterBtn4} 
                   style={{ backgroundColor: (hoverState[16].hovered || currentFilter == "Puzzle") ? "#fff" : "#2d2d2d" }}
                   aria-label="Show Puzzle genre"
                  >
                     <Puzzle 
                       className={styles.filterSVG2} 
                       style={{ fill: (hoverState[16].hovered || currentFilter == "Puzzle") ? "#000000" : "#fff" }} 
                      />
                 </button>
                 Puzzle
            </div>

            <div 
              className={styles.filterDiv} 
              id="17" 
              onMouseEnter={handleHover} 
              onMouseLeave={handleHover} 
              onClick={handleSelect}
            >
                 <button 
                   className={styles.filterBtn3} 
                   style={{ backgroundColor: (hoverState[17].hovered || currentFilter == "Racing") ? "#fff" : "#2d2d2d" }}
                   aria-label="Show Racing genre"
                 >
                     <Racing 
                       className={styles.filterSVG3} 
                       style={{ fill: (hoverState[17].hovered || currentFilter == "Racing") ? "#000000" : "#fff" }} 
                      />
                 </button>
                 Racing
            </div>

            <div 
              className={styles.filterDiv} 
              id="18" 
              onMouseEnter={handleHover} 
              onMouseLeave={handleHover} 
              onClick={handleSelect}
            >
                 <button 
                   className={styles.filterBtn4} 
                   style={{ backgroundColor: (hoverState[18].hovered || currentFilter == "Sports") ? "#fff" : "#2d2d2d" }}
                   aria-label="Show Sports genre"
                 >
                     <Sports 
                       className={styles.filterSVG3} 
                       style={{ fill: (hoverState[18].hovered || currentFilter == "Sports") ? "#000000" : "#fff" }} 
                      />
                 </button>
                 Sports
            </div>
            */}
          </div>
        </div>
    )
}

export default Filters;