const filterNames = [
    "Wishlist",
    "Ratings",
    "Reviews",
    "Polos",
    "Conjuntos",
    "Vape",
    "Calcetines",
    "Adventure",
    "Puzzle",
    "Racing",
    "Sports"
];

export default filterNames;