import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { ReactComponent as Right } from "../../Resources/image/arrowRight.svg";
import { ReactComponent as Cross } from "../../Resources/image/cross.svg";
import AnimatedCart from '../../Containers/AnimatedPage/AnimatedCart';
import styles from './Cart.module.css';

const Cart = (props) => {
    const {
        cartAmount,
        cart,
        handleOpenCart,
        handleCloseCart,
        handleHover,
        hoverState,
        clearCart,
        handleRemoveFromCart,
        openGamePage,
        selectedTalla
    } = props;

    const [total, setTotal] = useState(0);
    console.log(selectedTalla)
    useEffect(() => {
        let newTotal = 0;
        cart.forEach(item => {
            newTotal += parseFloat(item.price);
        });
        setTotal(newTotal.toFixed(2));
    }, [cart]);

    const generateWhatsAppMessage = () => {
        let message = "Hola 😊, estoy interesado en estos productos 📦:\n";
        cart.forEach(item => {
            message += `- ${item.name} a S/${item.price}\n`;
            if (selectedTalla) {
                message += `  Talla: ${selectedTalla}\n`;
            }
        });
        message += `Total: S/${total}`;
        return encodeURIComponent(message);
    };

    const variants = {
        initial: { x: 100 },
        animate: { x: 0, transition: { x: { type: "spring", duration: 1.2, bounce: 0.4 } } },
        exit: { x: 100, transition: { x: { type: "spring", duration: 1.2, bounce: 0.575 } } },
    };

    return (
        <div className={styles.cartWindow}>
            <div className={styles.back} onClick={handleCloseCart}></div>
            <AnimatedCart>
                <div className={styles.cart} style={{ backgroundColor: "#1A1A1A", height: "100vh" }}>
                    <div className={styles.top}>
                        <div className={styles.topHeader}>
                            <h2>{cartAmount >= 1 ? cartAmount > 1 ? `${cartAmount} items` : "1 item" : "No hay productos"}</h2>
                            <h3 onClick={clearCart}>{cartAmount >= 1 ? "Clear" : ""}</h3>
                        </div>
                        <div className={styles.topGames}>
                            {cart.map((item, i) => (
                                <motion.div 
                                    className={styles.item} 
                                    key={i} 
                                    variants={variants} 
                                    initial="initial" 
                                    animate="animate" 
                                    exit="exit"
                                >
                                    <h3 id={item.surname} onClick={openGamePage}>{item.name}</h3>
                                    <div>
                                        S/{item.price}
                                        {selectedTalla && (
                                            <div className={styles.talla}>
                      <p>Talla: {selectedTalla}</p>
                      </div>
                                        )}
                                        <button 
                                            id={item.id} 
                                            onClick={handleRemoveFromCart} 
                                            className={styles.removeButton} 
                                            aria-label="Close"
                                        >
                                            <Cross className={styles.cross} />
                                        </button>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.bottom} style={{ width: "87.5%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h3>Total: S/{total}</h3>
                        <button 
                            id="24" 
                            onMouseEnter={handleHover} 
                            onMouseLeave={handleHover} 
                            onClick={() => window.location.href = `https://api.whatsapp.com/send?phone=51923234529&text=${generateWhatsAppMessage()}`} 
                            style={{ color: hoverState[24].hovered ? "#92f" : "#fff" }} 
                            aria-label="Checkout"
                        >
                            Checkout
                            <Right 
                                className={styles.right}
                                style={{ fill: hoverState[24].hovered ? "#92f" : "#fff" }}
                            />
                        </button>
                    </div>
                </div>
            </AnimatedCart>
        </div>
    );
};

export default Cart;
